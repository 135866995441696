import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { changeGalleryImageModal, getGalleryImageModal } from '../../store/app';
import ModalImage from './ModalImage';

const GalleryModalImage = () => {
  const data = useStaticQuery(graphql`
    query getModalImages {
      allFile(filter: { name: { regex: "/^gallery/" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(quality: 70, height: 1080, placeholder: BLURRED)
          }
        }
      }
    }
  `);
  const name = useSelector(getGalleryImageModal);
  const dispatch = useDispatch();
  const currentImage = data.allFile.nodes.find(image => image.name === name);
  return (
    <ModalImage
      handleClose={() => dispatch(changeGalleryImageModal(null))}
      alt={currentImage.name + ' full screen'}
      image={currentImage}
    />
  );
};

export default GalleryModalImage;
