import React from 'react';
import styled from 'styled-components';
import {
  CloseButton,
  TransparentBackgroundModalContainer,
} from '../../styles/shared';
import { colors } from '../../styles/theme';

const ModalVideo = ({ src, handleClose }) => {
  const handleClick = e => {
    // filter out click events on video
    e.stopPropagation();
    console.log(e.target.tagName);
    e.target.tagName !== 'VIDEO' && handleClose();
  };
  return (
    <TransparentBackgroundModalContainer onClick={handleClick}>
      <CloseButton
        color={colors.white}
        size='2.5rem'
        alt='close button'
        onClick={handleClose}
      />
      <Video width='100%' autoPlay controls>
        <source src={src} type='video/mp4' />
      </Video>
    </TransparentBackgroundModalContainer>
  );
};

const Video = styled.video`
  grid-area: 1 / 1;
  max-height: 100vh;
`;
export default ModalVideo;
