import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useDispatch } from 'react-redux';
import { changeGalleryImageModal, changeGalleryVideoModal } from '../store/app';
import { galleryPageContent } from '../utils/dataConfig';
import { devices } from '../styles/theme';
import { AiFillPlayCircle } from '@react-icons/all-files/ai/AiFillPlayCircle';
const twoAcross = { column: 'span 2' };
const firstTabSpans = [twoAcross];
firstTabSpans[6] = twoAcross;
firstTabSpans[11] = twoAcross;
firstTabSpans[16] = twoAcross;
firstTabSpans[17] = twoAcross;
firstTabSpans[22] = twoAcross;

const gridSpans = {
  [galleryPageContent.tabs[0].name]: firstTabSpans,
  [galleryPageContent.tabs[1].name]: [{ column: 'span 2' }],
  [galleryPageContent.tabs[2].name]: [{ column: 'span 2' }],
  [galleryPageContent.tabs[3].name]: [{ column: 'span 2' }],
};
const GalleryImages = ({ images, tab }) => {
  const dispatch = useDispatch();
  const hasVideo = ['mountain', 'lake', 'forest'].includes(tab);
  const handleClick = (image, index) => {
    if (hasVideo && index === 0) {
      // first image brings up video
      dispatch(changeGalleryVideoModal(galleryPageContent.videos[tab]));
    } else {
      dispatch(changeGalleryImageModal(true, image.name));
    }
  };
  return (
    <>
      {images.map((image, index) => (
        <Container
          key={image.name}
          onClick={() => handleClick(image, index)}
          gridSpan={gridSpans[tab][index]}
        >
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            style={{ height: '100%', gridArea: '1 / 1' }}
            alt={tab + ' image'}
          />
          {hasVideo && index === 0 && (
            <AiFillPlayCircle size='4rem' className='play-icon' />
          )}
        </Container>
      ))}
    </>
  );
};
const Container = styled.div`
  cursor: pointer;
  grid-row: ${props => props.gridSpan?.row || 'span 1'};
  grid-column: ${props => props.gridSpan?.column || 'span 1'};
  display: grid;
  @media (${devices.xs}) {
    grid-row: span 1;
    grid-column: span 1;
  }
  .play-icon {
    grid-area: 1 / 1;
    z-index: 1;
    justify-self: center;
    align-self: center;
    //margin: 2% 3%;
    opacity: 0.6;
  }
  :hover {
    .play-icon {
      opacity: 1;
    }
  }
`;

export default GalleryImages;
