import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import { colors, devices, fontWeights } from '../styles/theme';
import { galleryPageContent } from '../utils/dataConfig';
import { graphql, navigate } from 'gatsby';
import GalleryImages from '../components/GalleryImages';
import Modal from '../components/Modal';
import GalleryModalImage from '../components/modals/GalleryModalImage';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeGalleryImageModal,
  changeGalleryTab,
  changeGalleryVideoModal,
  getGalleryImageModal,
  getGalleryTab,
  getGalleryVideoModal,
} from '../store/app';
import { storeReferralData } from '../utils/helpers';
import SEO from '../components/SEO';
import ModalVideo from '../components/modals/ModalVideo';

const GalleryPage = ({ data, location }) => {
  const currentTab = useSelector(getGalleryTab);
  const firstTabImages = data.allFile.nodes
    .filter(image =>
      image.name.startsWith(`gallery-${galleryPageContent.tabs[0].name}-`)
    )
    .sort((a, b) => (a.name > b.name ? 1 : -1));
  const [currentImages, setImages] = useState(firstTabImages);
  const handleSwitchTab = name => {
    dispatch(changeGalleryTab(name));
    const images = data.allFile.nodes
      .filter(image => image.name.startsWith(`gallery-${name}-`))
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    // force unmount images
    setImages(null);
    setTimeout(() => setImages(images));
  };
  const imageModal = useSelector(getGalleryImageModal);
  const videoModal = useSelector(getGalleryVideoModal);
  const dispatch = useDispatch();

  // return to default tab when unmount
  useEffect(() => {
    if (location.search) {
      // parse search params
      const params = new URLSearchParams(location.search);
      storeReferralData(params);
      const tab = params.get('t');
      galleryPageContent.tabs.some(t => t.name === tab) &&
        dispatch(changeGalleryTab(tab));
      tab && navigate('/gallery/', { replace: true });
    }
    return () => dispatch(changeGalleryTab(galleryPageContent.tabs[0].name));
  }, []);
  // const tabName = galleryPageContent.tabs.find(
  //   ({ name }) => name === currentTab
  // ).title;
  return (
    <>
      <SEO title='Gallery' />
      <Layout>
        <Container>
          <Tabs>
            {galleryPageContent.tabs.map(({ name, title }) => (
              <h1
                key={name}
                aria-current={currentTab === name}
                onClick={() => handleSwitchTab(name)}
              >
                {title}
              </h1>
            ))}
          </Tabs>
          <ImageGrid>
            {currentImages && (
              <GalleryImages images={currentImages} tab={currentTab} />
            )}
          </ImageGrid>
        </Container>
        {imageModal && (
          <Modal
            anchorStyle={{ width: '100%', height: '100%' }}
            handleClose={() => dispatch(changeGalleryImageModal(null))}
          >
            <GalleryModalImage />
          </Modal>
        )}
        {videoModal && (
          <Modal
            anchorStyle={{ width: '100%', height: '100%' }}
            handleClose={() => dispatch(changeGalleryVideoModal(null))}
          >
            <ModalVideo
              handleClose={() => dispatch(changeGalleryVideoModal(null))}
              src={videoModal}
            />
          </Modal>
        )}
      </Layout>
    </>
  );
};

const Container = styled.section`
  padding: var(--body-padding-y) var(--body-padding-x);
`;

const Tabs = styled.ul`
  margin-bottom: 0.625rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  column-gap: 1rem;

  & > h1 {
    font-weight: ${fontWeights.medium};
    font-size: 1.25rem;
    line-height: 2rem;
    cursor: pointer;
    color: ${colors.darkGray};

    &[aria-current='true'] {
      color: ${colors.black};
      border-bottom: 0.25rem solid ${colors.blue};
    }
  }
`;
const ImageGrid = styled.section`
  display: grid;
  --square-size: min(
    450px,
    calc((100vw - var(--body-padding-x) * 2 - var(--gallery-grid-gap) * 2) / 3)
  );
  grid-template-columns: repeat(3, var(--square-size));
  grid-auto-rows: var(--square-size);
  grid-gap: var(--gallery-grid-gap);

  > * {
    color: ${colors.white};
  }
  @media (${devices.xs}) {
    --square-size: calc(100vw - var(--body-padding-x) * 2);
    grid-template-columns: 1fr;
  }
`;

export default GalleryPage;

export const pageQuery = graphql`
  query getGalleryImages {
    allFile(filter: { name: { regex: "/^gallery/" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(quality: 70, width: 1440, placeholder: BLURRED)
        }
      }
    }
  }
`;
